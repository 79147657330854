import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import HeroWithImage from "../components/heroWithImage/index";
import Layout from "../components/layout";
import Seo from "../components/seo";
import MediaReleases from "../containers/news/blog/media-releases";

import heroDesktop from "../assets/heroDesktop/Media.png";
import heroMobile from "../assets/heroMobile/Media.png";

const MediaReleasesPage = ({ data }) => {
  const { edges } = data.news;
  const intl = useIntl();
  return (
    <Layout inverted>
      <Seo title="MEDIA" />
      <HeroWithImage
        title={intl.locale === "fr" ? "MÉDIAS" : "MEDIA"}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <MediaReleases data={edges} />
    </Layout>
  );
};

export const query = graphql`
  query MediaReleasesQuery($locale: String) {
    news: allContentfulMediaUpdates(filter: { node_locale: { eq: $locale } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          ctaText
          ctaLink
          videoLink
          title
          date(formatString: "MMMM DD, YYYY")
          previewImage {
            file {
              url
            }
            title
          }
          content {
            raw
          }
        }
      }
    }
  }
`;

export default MediaReleasesPage;
